var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"mail-templates-table",attrs:{"column":"","fill-height":""}},[_c('v-breadcrumbs',{staticClass:"pa-2",attrs:{"items":_vm.breadcrumbItems,"divider":">"}}),_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-card-title',{staticClass:"row wrap px-0 pt-1"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_c('span',{staticClass:"display-1 font-weight-thin"},[_vm._v("Mail Templates")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"pr-2",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"paste":function($event){$event.preventDefault();return _vm.searchOnPaste.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","to":{ name: 'create-mail-template' }}},on),[_c('v-icon',[_vm._v("add")])],1)]}}])},[_c('span',[_vm._v("New Mail Template")])])]],2)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.mailTemplates,"footer-props":_vm.footerProps,"loading":_vm.$apollo.loading,"options":_vm.options,"server-items-length":_vm.mailTemplatesCount,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.mailTemplateType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm._.find(_vm.mailTemplateTypes, function (x) { return x.value === item.mailTemplateType; }), 'text'))+" ")]}},{key:"item.mailTemplateSubType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._.get(_vm._.find(_vm.mailTemplateSubTypes, function (x) { return x.value === item.mailTemplateSubType; }), 'text'))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","text":"","to":{ name: 'edit-mail-template', params: { id: item.id } }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])])]]}}])}),_c('pagination',{attrs:{"options":_vm.options,"totalPage":_vm.totalPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }