import Pagination from '@/components/Pagination/Pagination.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import QueryMixin from '@/mixins/query.mixin'
import MailTemplateMixin from '@/mixins/mailTemplate.mixin'

export default {
  props: ['updatedItem'],
  mixins: [GraphQlMixin, QueryMixin, MailTemplateMixin],
  components: {
    Pagination
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Settings',
          to: { name: 'settings' },
          exact: true
        },
        {
          text: 'Mail Templates',
          disabled: true
        }
      ],
      mailTemplates: [],
      mailTemplatesCount: 0,
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Subject', value: 'subject' },
        { text: 'Type', value: 'mailTemplateType'},
        { text: 'Subtype', value: 'mailTemplateSubType'},
        { text: 'Actions', value: 'action', sortable: false }
      ],
      search: '',
      debouncing: false,
      debounceTimeout: null
    }
  },
  computed: {
    totalPage() {
      return this.options.itemsPerPage === -1 || this.mailTemplatesCount === 0
        ? 1
        : Math.ceil(this.mailTemplatesCount / this.options.itemsPerPage)
    }
  },
  watch: {
    options: {
      handler() {
        this.$apollo.queries.mailTemplates.refetch()
      },
      deep: true
    },
    search() {
      this.$apollo.queries.mailTemplates.refetch()
    }
  },
  apollo: {
    mailTemplates: {
      query() {
        const fields = this.getFieldsGql('read', 'MailTemplate', [
          'id',
          'name',
          'description',
          'subject',
          'mailTemplateType',
          'mailTemplateSubType'
        ])
        return this.$gql`
          query SearchMailTemplates(
            $q: String
            $take: Int
            $skip: Int
            $orderBy: [OrderBy]
          ) {
            mailTemplates(search: $q, take: $take, skip: $skip, orderBy: $orderBy) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return {
          q: this.search,
          take: this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
          skip: this.options.itemsPerPage === -1 ? 0 : this.options.itemsPerPage * (this.options.page - 1),
          orderBy: this.getOrderBy()
        }
      },
      skip() {
        return (
          !_.get(this, 'options.page') || this.debouncing || this.loadingQuery || !this.ability.can('read', 'MailTemplate')
        )
      },
      result({ data, error }) {
        if (data && data.mailTemplates) {
          if (this.updatedItem !== undefined) {
            for (let i = 0; i < data.mailTemplates.items.length; i++) {
              if (data.mailTemplates.items[i].id === this.updatedItem.id) {
                data.mailTemplates.items[i] = this.updatedItem
                break 
              }
            }
          }
          this.mailTemplates = data.mailTemplates.items
          this.mailTemplatesCount = data.mailTemplates.totalCount
        } else if (error) {
          this.graphQLOnError(`Failed to get list of mail templates. ${error.toString()}`)
        }
      }
    }
  },
  created() {
    if (!this.ability.can('read', 'MailTemplate')) {
      this.$router.push('/')
    }
  }
}